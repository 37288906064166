import React, { useState, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Container, Input, Row, Space, FormGroup } from '@prism/library'
import { ConstraintConstant, HYPHEN_STRING, KEY_CODE, MessageTypeConstant, SPACE_STRING } from '../../utils/constant'
import { SearchSellerPanel } from '../common/vehicle-info/SearchSellerPanel'
import {
    handleErrorException,
    handleEventOnKeyDownForNumber,
    isBlank, openSnowModal
} from '../../utils/utils'
import { isValidNumber } from '../../utils/validation-utils'
import { NotificationMessage } from '../common/notification-message/NotificationMessage'
import { isEqual } from 'lodash'
import { addReservation } from '../../utils/data-source/lane-management-datasource'

import './lane-management-add-run.scss'
import useCustomLocation from '../../hooks/common/UseCustomLocation';
import DefaultModalWithChild from '../common/modal/DefaultModalWithChild'

export function LaneManagementAddRun({
                                         runRequestData,
                                         isAddRunOpen,
                                         setIsAddRunOpen,
                                         setVehicles,
                                         setLastSaved
                                     }) {

    const {t} = useTranslation()
    const [messageAddRun, setMessageAddRun] = useState({hasError: false, message: ""})
    const [shownSearchSellerPanel, setShownSearchSellerPanel] = useState(false)
    const [sellerSearchData, setSellerSearchData] = useState(null)
    const [sellerSearchCustomers, setSellerSearchCustomers] = useState(null)
    const [addRunPayload, setAddRunPayload] = useState({})
    const [hasData, setHasData] = useState(false)
    const {pathName} = useCustomLocation()
    const sellerRef = useRef()

    const onClickButtonSearchSeller = useCallback((e) => {
        setShownSearchSellerPanel(true)
    }, [])

    function validateAddRunRequest() {

        if (addRunPayload.sellerNumber && !isValidNumber(addRunPayload.sellerNumber)) {
            let err = {
                hasError: true,
                message: t('MESSAGE.COMMON.ERROR.VEHICLE_DETAIL.SELLER_NUMBER_ERROR_FORMAT')
            }
            setMessageAddRun(err)
            return
        }

        if (!isBlank(addRunPayload.runCount)) {
            if (!isValidNumber(addRunPayload.runCount) || addRunPayload.runCount < ConstraintConstant.RUN_COUNT_MIN_VALUE || addRunPayload.runCount > ConstraintConstant.RUN_COUNT_MAX_VALUE) {
                let err = {
                    hasError: true,
                    message: t('LABEL.LANE_MANAGEMENT.MESSAGE.RUN_COUNT_ERROR_FORMAT')
                }
                setMessageAddRun(err)
                return
            }
        }

        if (!isBlank(addRunPayload.startingRun)) {
            if (!isValidNumber(addRunPayload.startingRun) || addRunPayload.startingRun < ConstraintConstant.STARTING_RUN_MIN_VALUE || addRunPayload.startingRun > ConstraintConstant.STARTING_RUN_MAX_VALUE) {
                let err = {
                    hasError: true,
                    message: t('LABEL.LANE_MANAGEMENT.MESSAGE.STARING_RUN_ERROR_FORMAT')
                }
                setMessageAddRun(err)
                return
            }
        }

        if (!isBlank(addRunPayload.sellerNumber) && !isBlank(addRunPayload.runCount)) {
            setHasData(true)
        } else {
            setHasData(false)
        }

        setMessageAddRun({
            hasError: false,
            message: ""
        })
    }

    function handlePressEnter(event) {
        if(event.keyCode === KEY_CODE.ENTER && hasData && !messageAddRun.hasError) {
            handleAddRun()
        }
    }

    useEffect(() => {
        if (isAddRunOpen && !shownSearchSellerPanel) {
            // Delay focus to ensure ref is properly set
            const timeout = setTimeout(() => {
                if (sellerRef.current) {
                    sellerRef.current.focus();
                }
            }, 100);

            return () => clearTimeout(timeout); // Clean up timeout on component unmount or change
        }
    }, [isAddRunOpen, shownSearchSellerPanel]);

    useEffect(() => {
        validateAddRunRequest()
    }, [addRunPayload])

    function handleAddRun() {
        const request = {...runRequestData, ...addRunPayload}
        setMessageAddRun({hasError: false, message: ""})
        addReservation(request).then((response) => {
            setVehicles(response.data?.vehicleRuns)
            setLastSaved(response.data?.savedLane)
            setMessageAddRun({hasError: false, message: response.data?.message, status: response.status})
        }).catch((ex) => {
            let error = handleErrorException(ex)
            setMessageAddRun({...messageAddRun, hasError: true, message: error.message, status: error.status})
        });
    }

    function buildErrorMessage() {
        if(messageAddRun.message.includes(t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.CREATE_REQUEST_INPUT_ERROR.LINK_TEXT'))) {
            let error = messageAddRun.message.split(t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.CREATE_REQUEST_INPUT_ERROR.LINK_TEXT'))
            return (
                <> {error[0]}
                    <a href={pathName} onClick={openSnowModal}>
                        {t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.CREATE_REQUEST_INPUT_ERROR.LINK_TEXT')}
                    </a>
                    {error[1]}
                </>
            )
        } else {
            return messageAddRun.message;
        }
    }
    function renderErrorMessage() {
        if (messageAddRun.hasError && !!messageAddRun.message && isEqual(messageAddRun.status, 500)) {
            return (
                <NotificationMessage
                    className='error-message pl-0'
                    type={MessageTypeConstant.ERROR}
                    isOpen={true}>
                    {t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.CREATE_REQUEST_INPUT_ERROR.PRE_TEXT')}
                    <a href={pathName} onClick={openSnowModal}>
                        {t('MESSAGE.COMMON.ERROR.LANE_MANAGEMENT.CREATE_REQUEST_INPUT_ERROR.LINK_TEXT')}
                    </a>
                </NotificationMessage>
            )
        } else if (messageAddRun.hasError && !!messageAddRun.message) {
            return (
                <NotificationMessage type={'error'} isOpen={true} className='error-message pl-0'>
                    {buildErrorMessage()}
                </NotificationMessage>
            )
        } else if (!messageAddRun.hasError && !!messageAddRun.message && isEqual(messageAddRun.status, 200)) {
            return (
                <NotificationMessage type={MessageTypeConstant.SUCCESS} isOpen={true} className='error-message pl-0'>
                    {messageAddRun.message}
                </NotificationMessage>
            )
        }
    }

    return (
        <>
            <DefaultModalWithChild
                isOpen={isAddRunOpen && !shownSearchSellerPanel}
                header={t('LABEL.LANE_MANAGEMENT.ADD_RUN.ADD_RUN_TITTLE')}
                onCloseClick={() => {
                    setIsAddRunOpen(false)
                }}
            >
                <Container className='prism-overridden lane-management-add-run'>
                    {renderErrorMessage()}

                    <Space vertical size='0'>
                        <Row className='ml-0 mr-1'>
                            <Col md={12} sm={12} xs={12} className='pl-0'>
                                <span mauto='maLabelInfoMessage'>
                                   {t('LABEL.LANE_MANAGEMENT.ADD_RUN.MESSAGE')}
                               </span>
                            </Col>
                        </Row>
                        <Row className='ml-1'>
                            <Col md={4} sm={4} xs={4} className='pl-0 pr-1'>
                                <FormGroup row className={'input-seller-no'}>
                                    <Input.Label fontSize='sm' mauto='maLabelSellerNo'>
                                        {t('LABEL.LANE_MANAGEMENT.LABEL.SELLER_NO')}
                                    </Input.Label>
                                    <Input
                                        className='prism-input'
                                        value={addRunPayload.sellerNumber}
                                        placeholder={t('LABEL.LANE_MANAGEMENT.ADD_RUN.ENTER_HERE')}
                                        maxLength={ConstraintConstant.SELLER_NUMBER_MAX_LENGTH}
                                        mauto='maInputSellerNo'
                                        name='sellerNumber'
                                        onKeyDown={(event) =>{
                                            handleEventOnKeyDownForNumber(event)
                                            handlePressEnter(event)
                                        }}
                                        onChange={(e) => {
                                            setAddRunPayload({...addRunPayload, sellerNumber: e.target.value})
                                        }}
                                        onBlur={(e) => {
                                        }}
                                        innerRef={sellerRef}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={1} sm={1} xs={1}
                                 className='pl-1 mr-3 search-seller-btn'>
                                <Button className='btn btn-outline'
                                        onClick={onClickButtonSearchSeller}
                                        mauto='maSearchSellerBtn'>
                                    <i className='icon prism-icon-search'/>
                                </Button>
                            </Col>
                        </Row>
                        <Row className='ml-1 mb-5'>
                            <Col md={4} sm={4} xs={4} className='pl-0 pr-2'>
                                <FormGroup row className='mt-0'>
                                    <Input.Label fontSize='sm'mauto='maLabelRuns'>
                                        {t('LABEL.LANE_MANAGEMENT.ADD_RUN.RUN_NUMBER')}
                                    </Input.Label>
                                    <Input
                                        className='prism-input'
                                        value={addRunPayload.runCount}
                                        placeholder={t('LABEL.LANE_MANAGEMENT.ADD_RUN.ENTER_HERE')}
                                        maxLength={ConstraintConstant.RUN_COUNT_MAX_LENGTH}
                                        mauto='maInputRuns'
                                        onKeyDown={(event) =>{
                                            handleEventOnKeyDownForNumber(event)
                                            handlePressEnter(event)
                                        }}
                                        onChange={(e) => {
                                            setAddRunPayload({...addRunPayload, runCount: e.target.value})
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4} sm={4} xs={4} className='pl-1 pr-1'>
                                <FormGroup row className='mt-0'>
                                    <Input.Label fontSize='sm' mauto='maLabelStartingRun' className='text-nowrap'>
                                        {t('LABEL.LANE_MANAGEMENT.ADD_RUN.STARTING_RUN')}
                                    </Input.Label>
                                    <Input
                                        className='prism-input'
                                        value={addRunPayload.startingRun}
                                        placeholder={runRequestData.laneNumber + SPACE_STRING + HYPHEN_STRING}
                                        maxLength={ConstraintConstant.STARTING_RUN_MAX_LENGTH}
                                        mauto='maInputStartingRun'
                                        onKeyDown={(event) =>{
                                            handleEventOnKeyDownForNumber(event)
                                            handlePressEnter(event)
                                        }}
                                        onChange={(e) => {
                                            const {value} = e.target
                                            setAddRunPayload({...addRunPayload, startingRun: e.target.value})
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Space>

                    <div className='row lane-management-add-run-footer'>
                        <div className='flex-sm-row-reverse control-buttons'>
                            <Button
                                className='m-2'
                                mauto='maButtonAdd'
                                disabled={!hasData || messageAddRun.hasError}
                                onClick={() => {
                                    handleAddRun()
                                }}
                                size='sm'
                                color='primary'>
                                {t('LABEL.LANE_MANAGEMENT.ADD_RUN.ADD_BUTTON')}
                            </Button>
                        </div>
                    </div>
                </Container>
            </DefaultModalWithChild>

            {
                !!shownSearchSellerPanel
                && (
                    <SearchSellerPanel
                        auctionCode={runRequestData.auctionCode}
                        searchSellerOpen={shownSearchSellerPanel}
                        customers={sellerSearchCustomers}
                        searchData={sellerSearchData}
                        isVehicleDetailParticular={true}
                        setSearchSellerOpen={setShownSearchSellerPanel}
                        setSellerNumber={(value) => {
                            setAddRunPayload({...addRunPayload, sellerNumber: value})
                        }}
                        setCustomers={setSellerSearchCustomers}
                        setSearchData={setSellerSearchData}
                        setHasDataChanged={() => {
                        }}
                    />
                )
            }
        </>
    )
}