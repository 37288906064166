import { Modal } from "@prism/library";
import {useCallback, useEffect, useState} from "react";
import './default-modal-child.scss'
import Draggable from 'react-draggable'

export default function DefaultModalWithChild({
    header = '',
    onCloseClick = () => {},
    isOpen = false,
    children
}) {
    const [showModal, setShowModal] = useState(isOpen);
    const [isDraggable, setIsDraggable] = useState(true)

    useEffect(() => {
        setShowModal(isOpen);
    }, [isOpen]);

    const closeClickHandler = useCallback(() => {
        onCloseClick();
        setShowModal(false);
    }, [onCloseClick]);

    return (
        <Draggable disabled={!isDraggable} bounds={{top: -200, left: -window.innerWidth/2, right: window.innerWidth/2, bottom: window.innerHeight/2}}>
            <Modal className='default-modal custom-modal'
                isOpen={showModal}
                toggle={closeClickHandler}
                keyboard={false}
                backdrop={"static"}
            >
                <Modal.Header test-data-id="header"  toggle={closeClickHandler}>
                    <div dangerouslySetInnerHTML={{ __html: header }}/>
                </Modal.Header>
                <Modal.Body test-data-id="body">
                    <div
                        onMouseEnter={() => { setIsDraggable(false) }}
                        onMouseLeave={() => { setIsDraggable(true) }}
                    >
                        {children}
                    </div>
                </Modal.Body>
            </Modal>
        </Draggable>
    );
}